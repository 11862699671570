import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, catchError, concatMap, of } from 'rxjs';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {

  constructor(private router: Router, private spinner: NgxSpinnerService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = localStorage.getItem('token'); // or whatever key you use for the token

    // this.spinner.show();
    if (token) {
      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ` + JSON.parse(token)
        },
      });
    }

    return next.handle(request).pipe(
      concatMap((response: any) => {
        // 
        if (response instanceof HttpResponse) {

          // this.spinner.hide();
          if (response.body && response.body.status === 'error') {
            // Handle the error here
            console.log('Error occurred:', response.body.message);
          }
        }
        return of(response); // Return an empty observable
      }), catchError((error: any) => {
        this.spinner.hide();
        if (!request.url.includes('/rubric/course/') && request.method !== 'GET') {
          Swal.fire({
            title: "Error!",
            text: error.error.message || 'Unexpected Error',
            icon: "error",
            confirmButtonColor: '#198FD9'
          });
        }
        console.log('Error occurred:', error);
        console.log('Error occurred:', error.error.message);



        if (error.status === 401 || error.status === 403) {
          // // // console.log('Login failed. Redirecting to login page.');
          localStorage.clear()
          this.router.navigate(['/auth/login']);
        }

        return of(error); // Return an empty observable
      }));
  }
}
