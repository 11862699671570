import { Component } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Router, NavigationEnd } from '@angular/router';
import { Firestore, doc, updateDoc, setDoc, increment, getDoc, collection, getDocs, getFirestore, limit, query, where } from '@angular/fire/firestore';
import { initializeApp } from '@angular/fire/app';
import { getStorage } from 'firebase/storage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'byild';
  private db: any;
  private storage: any;
  vehicles: any[] = [];
  list: any[] = [];
  glist: any[] = [];
  loading = true;
  makeList: any[] = [];

  constructor(private router: Router, private analytics: AngularFireAnalytics) { }

  ngOnInit(): void {
    const firebaseConfig = {
      apiKey: "AIzaSyBAKDGQmn9J_r02jJamPtNy7Ch-DFoAU1s",
      authDomain: "byildlms-uat.firebaseapp.com",
      projectId: "byildlms-uat",
      storageBucket: "byildlms-uat.appspot.com",
      messagingSenderId: "358518936383",
      appId: "1:358518936383:web:adaf9cb01c8633d5b7b98a",
      measurementId: "G-2JLS2SFRM0"
    };

    const app = initializeApp(firebaseConfig);
    this.db = getFirestore(app);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.analytics.logEvent('page_view', {
          page_title: event.urlAfterRedirects,
          page_location: window.location.href
        });
      }
    });
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     // this.recordPageView(event.urlAfterRedirects);
    //     // if (event.urlAfterRedirects.includes('/portal')) {
    //     //   this.fetchPageViewData();
    //     // }
    //   }
    // });
  }

  openCat() {
    this.router.navigate(['/catalog']);
  }

  openCatDetails(car: any) {
    this.router.navigate(['/catalog/car'], { queryParams: { id: car.id } });
  }

  openCont() {
    this.router.navigate(['/contact-us']);
  }

  fetchRecords(): void {
    const recordsCollection = query(collection(this.db, 'vehicles'), where('status', '==', true), limit(4));
    getDocs(recordsCollection)
      .then((querySnapshot) => {
        this.list = querySnapshot.docs.map((doc) => doc.data());
        console.log(this.list);
        this.loading = false;
      })
      .catch((error) => {
        console.log('Error fetching records:', error);
        this.loading = false;
      });
  }

  // Function to increment page view count
  recordPageView(pagePath: string): void {
    const pageName = pagePath.split('/')[pagePath.split('/').length - 1].split('?')[0]; // Get the last part of the URL
    if (pageName) {
      const pageDocRef = doc(this.db, 'pageViews', pageName);

      // Check if document exists
      getDoc(pageDocRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            // Document exists, update the view count
            // to see if guest or learner is viewing the page

            updateDoc(pageDocRef, { views: increment(1), guests: localStorage.getItem('user') ? increment(0) : increment(1) })
              .catch(error => console.error('Error updating page views:', error));
          } else {
            // Document doesn't exist, create it with initial view count
            setDoc(pageDocRef, { views: 1, guests: localStorage.getItem('user') ? 0 : 1 })
              .catch(error => console.error('Error creating page view document:', error));
          }
        })
        .catch(error => console.error('Error checking page view document:', error));
    }
  }
  fetchPageViewData(): void {
    const pageViewsCollection = collection(this.db, 'pageViews'); // Ensure this.db is initialized properly
    getDocs(pageViewsCollection)
      .then((querySnapshot) => {
        let totalViews = 0;
        let pageViewData: any = querySnapshot.docs.map((doc) => {
          const data: any = doc.data();
          totalViews += data.views;
          return { page: doc.id, views: data.views };
        });

        // Find the most-viewed page
        const mostViewed = pageViewData.reduce((prev: { views: number; }, current: { views: number; }) => (prev.views > current.views ? prev : current), {});
        let mostViewedPage = mostViewed.page;

        localStorage.setItem('pageViewData', JSON.stringify(pageViewData));

      })
      .catch(error => console.error('Error fetching page view data:', error));
  }

}