export const environment = {
    // apiUrl: 'https://lmsuatservices.digiflow.io/byildlmsbackend/api/v1',
    // apiUrl: 'http://192.168.80.211:8080/api/v1',
    // apiUrl: 'https://lmsdevservices.digiflow.io/byildlmsdev/api/v1',
    apiUrl: 'https://lmsuatservices.digiflow.io/byildlmsuat/api/v1',

    // apiUrl: 'http://10.0.0.60:8080/api/v1',
    frontendUrl: 'https://lmsuatservices.digiflow.io/byildlmsdev',
    // apiUrl: 'http://192.168.100.206:8080/api/v1',

    // apiUrl: 'http://192.168.100.94:8080/api/v1',
    // frontendUrl:"http://localhost:4200/"

    firebase: {
        apiKey: "AIzaSyBAKDGQmn9J_r02jJamPtNy7Ch-DFoAU1s",
        authDomain: "byildlms-uat.firebaseapp.com",
        projectId: "byildlms-uat",
        storageBucket: "byildlms-uat.appspot.com",
        messagingSenderId: "358518936383",
        appId: "1:358518936383:web:adaf9cb01c8633d5b7b98a",
        measurementId: "G-2JLS2SFRM0"
    }
};









